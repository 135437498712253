<template>
    <div class="w-full flex lg:hidden justify-center text-center items-center text-lg font-bold text-slate-700">For better visibility and insights use a laptop or desktop computer</div>
    <div class="line-chart-container hidden lg:block">
        <!-- Chart container element -->
        <div class="labels">
            <div id="sp1" class="flex justify-start items-center block whitespace-pre-line border-red-500">
                <span class="w-1/6 text-[40px]"> {{ $t("Struggling talents") }}</span>
            </div>
            <div id="sp2" class="flex justify-end items-center whitespace-wrap">
                <span class="w-1/6"> {{ $t("Skilled talents") }}</span>
            </div>
            <div id="sp3" class="flex justify-start items-center whitespace-wrap">
                <span class="w-1/6"> {{ $t("lack of proficiency") }}</span>
            </div>
            <div id="sp4" class="flex justify-end items-center whitespace-wrap">
                <span class="w-1/6"> {{ $t("HIgh level of proficiency") }}</span>
            </div>
        </div>
        <div class="lineChartWrapper">
            <div class="time">
                <div class="glassEffect w-full h-full">
                    <h3 class="-rotate-90">{{ $t("TIME") }}</h3>
                </div>
            </div>

            <div ref="chartContainer" class="chart-svg-container" style="background-color: white">
                <div class="tooltip-container" @mouseout="handleCircleMouseOut" @mouseover="handleTooltipHover">
                    <CircleTooltip :circleData="circleData" :showTooltip="showTooltip" :tooltipX="tooltipX" :tooltipY="tooltipY" />
                </div>
                <div class="tooltip-container" @mouseout="handleGroupMouseOut">
                    <GroupTooltip :groupData="groupData" :showGroupTooltip="showGroupTooltip" :tooltipX="tooltipX" :tooltipY="tooltipY" />
                </div>
            </div>
        </div>
        <div class="score">
            <div class="glassEffect w-full h-full">
                <h3>{{ $t("TALENT SCORE") }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";
import CircleTooltip from "./CircleTooltip.vue"; // Adjust the path accordingly
import GroupTooltip from "./groupTooltip.vue";
export default {
    name: "TalentsMappingChart",
    components: { CircleTooltip, GroupTooltip },
    props: {
        graphData: Array, // Data for the chart
        // chartWidth: Number,
        chartHeight: Number,
    },
    watch: {
        // Watch for changes to the score prop
        graphData: {
            async handler(newVal, oldVal) {
                console.log("Watcher Triggered - Old Value:", oldVal);
                console.log("Watcher Triggered - New Value:", newVal);
                this.data = newVal;
                this.createChart(newVal);
                // this.forceRerender();
                // Update the local data variable or trigger necessary actions
            },
            immediate: true, // Trigger the watcher immediately on component creation
        },
    },
    data() {
        return {
            // Define any other chart-related data properties here
            width: 700,
            height: this.chartHeight,
            marginTop: 20,
            marginRight: 30,
            marginBottom: 30,
            marginLeft: 40,
            showTooltip: false,
            showGroupTooltip: false,
            circleData: null,
            groupData: null,
            tooltipX: 0,
            tooltipY: 0,

            // graphData: [
            //   // Provide your dataset here
            //   { Date: new Date("2023-01-01"), Close: 20 },
            //   { Date: new Date("2023-01-02"), Close: 60 },
            //   { Date: new Date("2023-01-03"), Close: 30 },
            //   { Date: new Date("2023-01-04"), Close: 50 },
            // ],
            // tooltipContent: "", // To store the content of the tooltip
            // tooltipVisible: false,
            // tooltipStyle: {
            //     opacity: 0,
            //     position: "absolute",
            //     pointerEvents: "none",
            //     transition: "opacity 0.2s",
            // }, // To manage the style of the tooltip
            // activeBar: null, // To keep track of the active bar for mouseout events
            // tooltipHideTimeout: null,
        };
    },
    mounted() {
        if (window.innerWidth > 900) {
            if (window.innerWidth > 1260) {
                this.width = 700;
            } else this.width = 500;
        } else {
            this.width = window.innerWidth * 0.6;
        }
        this.createChart(this.graphData);
        // console.log('width............', this.width)

        window.addEventListener("resize", this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            if (window.innerWidth > 900) {
                if (window.innerWidth > 1260) {
                    this.width = 700;
                } else this.width = 500;
            } else {
                this.width = window.innerWidth * 0.6;
            }
            // else{
            //
            //     }h
            // console.log('function width', this.width)
            this.createChart();
        },
        createChart(graphData) {
            // const colors = ["#7e84fb", "#ff6b6b", "#66d9e8", "#ffb86c", "#c0c0c0"];
            const container = d3.select(this.$refs.chartContainer);
            container.select("svg").remove();
            const x = d3
                .scaleLinear()
                .domain([0, 100]) // Assuming the maximum score is 100
                .nice()
                .range([this.marginLeft, this.width - this.marginRight]);

            const y = d3
                .scaleLinear()
                .domain([0, 60]) // Assuming 60 minutes as the maximum range
                .nice()
                .range([this.height - this.marginBottom, this.marginTop]);
            const svg = container.append("svg").attr("viewBox", [0, 0, this.width, this.height]).attr("width", this.width).attr("height", this.height);

            svg.style("overflow", "visible").style("border", "1px solid #a3aed0");
            // Append x-axis
            // Add a vertical line at 50%
            svg.append("line")
                .attr("x1", this.width / 2) // Position at 50% of the width
                .attr("y1", 0)
                .attr("x2", this.width / 2) // Position at 50% of the width
                .attr(
                    "y2",
                    this.height, //- 30
                ) // Assuming 'height' is the height of your SVG and adding a margin of 20 pixels
                .attr("stroke", "#a3aed0"); // Change color if needed
            // Add label for the vertical line
            svg.append("text")
                .attr("x", this.width / 2) // X position of the label (50% of the width)
                .attr("y", this.height - 5) // Y position of the label, slightly above the line
                .attr("text-anchor", "middle") // Anchor the text in the middle
                .attr("fill", "#a3aed0"); // Text color
            //.text("50%") // Label text

            // Group the data points based on rounded values of score and time
            const groupedData = d3.group(graphData, (d) => {
                const roundedScore = Math.round(d.score / 5) * 5; // Round score to the nearest 10
                const roundedTime = Math.round(d.time / 5) * 5; // Round time to the nearest 10
                return `${roundedScore},${roundedTime}`;
            });
            console.log({ groupedData });
            // Create a new array of data points with counts and positions
            const newData = Array.from(groupedData, ([position, data]) =>
                data.length === 1
                    ? {
                          position,
                          count: data.length,
                          img: data[0].img,
                          id: data[0].id,
                          initials: data[0].initials,
                          name: data[0].name,
                          score: data[0].score,
                          time: data[0].time,
                      }
                    : {
                          position,
                          count: data.length,
                          data,
                      },
            );

            // Append circles to represent grouped data points
            svg.selectAll(".grouped-circle")
                .data(newData)
                .enter()
                .append("circle")
                .attr("class", "grouped-circle")
                .attr("cx", (d) => x(parseFloat(d.position.split(",")[0])))
                .attr("cy", (d) => y(parseFloat(d.position.split(",")[1])))
                .attr("r", (d) => Math.sqrt(d.count) * 10) // Scale radius based on count
                .attr("fill", (d) => (d.count === 1 ? `url(#pattern-${d.position})` : "blue")) // Apply pattern fill for single circles
                .attr("stroke", "#fff") // Add a stroke around the circles
                .attr("stroke-width", 2) // Adjust the stroke width
                .on("mouseover", (event, d) => {
                    // Handle mouseover event
                    console.log("Mouseover event", d);
                    this.handleGroupHover(event, d.data);
                    console.log(`Group at position ${d.position} with ${d.count} overlapping circles.`);
                })
                .on("mouseout", () => this.handleGroupMouseOut());

            // Append text or image based on the count of overlapping circles
            svg.selectAll(".grouped-element")
                .data(newData)
                .enter()
                .append("g")
                .attr("class", "grouped-element")
                .attr("r", (d) => Math.sqrt(d.count) * 10)
                .each(function (d) {
                    const group = d3.select(this);
                    console.log({ d, group });
                    if (d.count === 1) {
                        // If only one circle and img exists, append image
                        if (d.img) {
                            group
                                .append("clipPath") // Apply clip path to create a circular mask
                                .attr("id", `clip-${d.position}`)
                                .append("circle")
                                .attr("cx", x(parseFloat(d.position.split(",")[0])))
                                .attr("cy", y(parseFloat(d.position.split(",")[1])))
                                .attr("r", 20); // Radius should match the radius of the circle

                            group
                                .append("image")
                                .attr("xlink:href", `data:image/png;base64,${d.img}`)
                                .attr("x", (d) => x(parseFloat(d.position.split(",")[0])) - 20)
                                .attr("y", (d) => y(parseFloat(d.position.split(",")[1])) - 20)
                                .attr("width", 40)
                                .attr("height", 40)
                                .attr("clip-path", `url(#clip-${d.position})`); // Apply clip path to the image
                        } else {
                            group
                                .append("circle")
                                .attr("cx", (d) => x(parseFloat(d.position.split(",")[0])))
                                .attr("cy", (d) => y(parseFloat(d.position.split(",")[1])))
                                .attr("r", 20) // radius of the circle
                                .attr("fill", "#828CFD"); // background color of the circle

                            group
                                .append("text")
                                .attr("text-anchor", "middle")
                                .attr("x", (d) => x(parseFloat(d.position.split(",")[0])))
                                .attr("y", (d) => y(parseFloat(d.position.split(",")[1])) + 5) // adjust y position to center text vertically
                                .attr("fill", "#fff") // text color
                                .attr("font-size", "14px")
                                .attr("font-weight", "700")
                                .attr("font-family", "Arial, sans-serif")
                                .text((d) => d.initials); // assuming you have a function to get initials
                        }
                    } else {
                        // If more than one circle, append count text
                        group
                            .append("text")
                            .attr("text-anchor", "middle")
                            .attr("x", (d) => x(parseFloat(d.position.split(",")[0])))
                            .attr("y", (d) => y(parseFloat(d.position.split(",")[1])))
                            .attr("dy", 4)
                            .attr("fill", "#fff")
                            .attr("font-size", "12px")
                            .attr("font-family", "Arial, sans-serif")
                            .text((d) => d.count);
                    }
                })
                .on("mouseover", (event, d) => {
                    d.count === 1 && this.handleCircleHover(event, d);
                })
                .on("mouseout", () => this.handleCircleMouseOut());

            // d3.select(".tooltip-container")
            //     .on("mouseover", () => this.handleTooltipHover())
            //     .on("mouseout", () => this.handleCircleMouseOut());

            // Calculate the y-coordinate for 30 minutes (representing 50% of the chart)
            const yValueFor30Minutes = y(30);

            // Add label for 30 minutes at 50% of the chart's width
            svg.append("text")
                .attr("x", 0) // X position of the label
                .attr("y", y(30) - 5) // Y position of the label, adjusted slightly above the line
                .attr("fill", "#a3aed0"); // Text color
            // .text("30 min");

            // Append a horizontal line at the y-coordinate for 30 minutes
            svg.append("line")
                .attr(
                    "x1",
                    0, //this.marginLeft
                )
                .attr("y1", yValueFor30Minutes + 5)
                .attr(
                    "x2",
                    this.width - 0, //this.marginRight
                )
                .attr("y2", yValueFor30Minutes)
                .attr("stroke", "#a3aed0");

            /////////////////////////////////////////

            const rect1 = svg
                .append("rect")
                .attr("id", "rec1")
                .attr("x", 0)
                .attr("y", 0)
                .attr("height", this.height / 2)
                .attr("width", this.width / 2)
                .attr("fill", "#00000000")
                .on("mouseover", function () {
                    //    var p = document.getElementById("p");
                    //d3.select(this).style("fill", "#2371b631");
                    rect1.attr("fill", "#2371b631");

                    //  p.style.visibility = "visible";
                    var sp = document.getElementById("sp1");

                    sp.style.color = "#000";
                })
                .on("mouseout", function () {
                    var sp = document.getElementById("sp1");

                    sp.style.color = "#5d5d5f";
                    //var p = document.getElementById("p");
                    //d3.select(this).style("fill", "#00000000");
                    rect1.attr("fill", "#00000000");

                    //p.style.visibility = "hidden";
                });
            rect1.lower();
            ////////////////RECT4
            const rect4 = svg
                .append("rect")
                .attr("x", this.width / 2)
                .attr("y", this.height / 2)
                .attr("height", this.height / 2)
                .attr("width", this.width / 2)
                .attr("fill", "#00000000")
                .on("mouseover", function () {
                    //var p = document.getElementById("pppp");
                    //d3.select(this).style("fill", "#2371b631");
                    rect4.attr("fill", "#2371b631");
                    var sp = document.getElementById("sp4");

                    sp.style.color = "#000";

                    //p.style.visibility = "visible";
                })
                .on("mouseout", function () {
                    //var p = document.getElementById("pppp");
                    //d3.select(this).style("fill", "#00000000");
                    rect4.attr("fill", "#00000000");
                    var sp = document.getElementById("sp4");

                    sp.style.color = "#5d5d5f";

                    //p.style.visibility = "hidden";
                });
            rect4.lower();

            ///////////////////RECT2
            const rect2 = svg
                .append("rect")
                .attr("x", this.width / 2)
                .attr("y", 0)
                .attr("height", this.height / 2)
                .attr("width", this.width / 2)
                .attr("fill", "#00000000")
                .on("mouseover", function () {
                    //var p = document.getElementById("pp");
                    //d3.select(this).style("fill", "#2371b631");
                    rect2.attr("fill", "#2371b631");
                    var sp = document.getElementById("sp2");

                    sp.style.color = "#000";

                    //p.style.visibility = "visible";
                })
                .on("mouseout", function () {
                    var sp = document.getElementById("sp2");

                    sp.style.color = "#5d5d5f";

                    //var p = document.getElementById("pp");
                    //d3.select(this).style("fill", "#00000000");
                    rect2.attr("fill", "#00000000");
                    //p.style.visibility = "hidden";
                });
            rect2.lower();

            //////////////////RECT3
            const rect3 = svg
                .append("rect")
                .attr("x", 0)
                .attr("y", this.height / 2)
                .attr("height", this.height / 2)
                .attr("width", this.width / 2)
                .attr("fill", "#00000000")
                .on("mouseover", function () {
                    //var p = document.getElementById("ppp");
                    //d3.select(this).style("fill", "#2371b631");
                    rect3.attr("fill", "#2371b631");
                    var sp = document.getElementById("sp3");

                    sp.style.color = "#000";

                    //p.style.visibility = "visible";
                })
                .on("mouseout", function () {
                    var sp = document.getElementById("sp3");

                    sp.style.color = "#5d5d5f";

                    //var p = document.getElementById("ppp");
                    //d3.select(this).style("fill", "#00000000");
                    rect3.attr("fill", "#00000000");
                    //p.style.visibility = "hidden";
                });
            rect3.lower();

            // Style the y-axis text
            svg.selectAll(".tick text")
                .attr("font-size", "12px") // Set font size
                .attr("fill", "#a3aed0");
        },
        handleBarHover(event, d) {
            console.log("handleBarHover", d);
            // {name: 'test test', initials: 'TT', score: 30, time: 1.59}
            if (this.activeBar && this.activeBar !== event.target) {
                d3.select(this.activeBar).attr("opacity", 1);
            }

            d3.select(event.target).attr("opacity", 0.7);

            this.activeBar = event.target;
            this.tooltipContent = `${d.name}: ${d.score}`;
            this.tooltipStyle.opacity = 0.9;
            clearTimeout(this.tooltipHideTimeout);
        },

        handleCircleHover(event, d) {
            // ... Your existing hover logic ...
            console.log({ d });
            this.circleData = d;
            console.log({ circleData: this.circleData.name });
            const containerRect = this.$refs.chartContainer.getBoundingClientRect();
            this.tooltipX = event.clientX - containerRect.left;
            this.tooltipY = event.clientY - containerRect.top;
            this.showTooltip = true;
        },
        handleGroupHover(event, d) {
            // ... Your existing hover logic ...
            console.log({ d });
            this.groupData = d;
            console.log({ groupDatadsfsdgsdfgsd: this.groupData });
            const containerRect = this.$refs.chartContainer.getBoundingClientRect();
            this.tooltipX = event.clientX - containerRect.left;
            this.tooltipY = event.clientY - containerRect.top;
            this.showGroupTooltip = true;
        },

        handleCircleMouseOut() {
            // ... Your existing mouseout logic ...

            this.showTooltip = false;
        },
        handleGroupMouseOut() {
            // ... Your existing mouseout logic ...

            this.showGroupTooltip = false;
        },
        handleTooltipHover() {
            this.showTooltip = true;
        },
        handleGroupTooltipHover() {
            this.showGroupTooltip = true;
        },
    },
};
</script>

<style scoped lang="scss">
.line-chart-container {
    /* Apply a drop shadow to the SVG container */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* width: 700px; */
    position: relative;
}

.path-line {
    fill: none;
    stroke-width: 2;
}

.path-area {
    fill-opacity: 0.6; /* Adjust the opacity as needed */
}
.tooltip-container {
    position: relative;
    z-index: 1; /* Ensure the tooltip container is above the circles */
}
.lineChartWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: -5%;
    padding-top: 2%;
    z-index: 5;
    background-color: transparent;
}
.time {
    font-size: 14px;
    font-weight: 600;
    //color: #a3aed0;
    /* margin-left: 40px;
    margin-bottom: 10px; */
    height: 600px;
    //width: 12px;

    // background-color: #2195f36c;
    //margin-right: 2px;
    /* From https://css.glass */
    // background: rgba(255, 255, 255, 0.2);
    // background-image: url("../assets/talentMap2.png");

    //background: linear-gradient(135deg, #2196f3 0%, #868cff 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    h3 {
        text-align: center;
        // height: fit-content;
        color: #000000;
        color: #2195f3;
        //transform: rotate(-90deg);
        font-size: 18px;
        // margin: 5;
        // padding: 10px;
    }
}
.score {
    font-size: 14px;
    font-weight: 600;
    //color: #a3aed0;
    /* margin-left: 40px;
    margin-bottom: 10px; */
    //height: 42px;
    //width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #2195f36c;
    //background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
    // background-image: url("../assets/talentMapImg.svg");
    // background-image: url("../assets/talentMap.png");
    //background-size: cover;
    //background-repeat: no-repeat;
    //background-position: center center;
    /* From https://css.glass */
    // border-top-right-radius: 5px;
    // border-bottom-left-radius: 5px;
    //border-bottom-right-radius: 5px;
    //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    //backdrop-filter: blur(9.5px);
    //-webkit-backdrop-filter: blur(9.5px);

    h3 {
        color: #000000;
        color: #2195f3;
        // transform: rotate(90deg);
        font-size: 18px;
        padding: 10px;
    }
}

.glassEffect {
    /* From https://css.glass */
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.labels {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: grid;
    // gap: 120%;
    grid-template-columns: 1fr 1fr;
    text-align: start;
    //z-index: 10000000000000000000;

    span {
        //margin-top: -3%;
        //z-index: 10000000000000000000;
        //margin-right: 200px;
        text-transform: uppercase;
        font-size: 1.2em;
        font-weight: 600;
        color: #5d5d5f;
    }
}
.chart-svg-container {
    position: relative; /* or absolute, fixed, sticky */
    overflow: visible;
    z-index: 5;

    svg {
        background-color: red;
    }
}
.tooltip {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
}
#sp1 {
    //position: absolute;
    left: -13%;
    z-index: 5;
}
#sp2 {
    //position: absolute;
    right: -13%;
    z-index: 5;
}
#sp3 {
    //  position: absolute;
    left: -13%;
    z-index: 5;
}
#sp4 {
    //    position: absolute;
    right: -13%;
    top: 140%;
    z-index: 5;
}
</style>
